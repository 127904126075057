import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  ButtonBase,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DomainIcon from '@mui/icons-material/Domain';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { PropTypes } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import {
  orange, green, lightBlue, aquaGreen, lightGray, navy, greenBoosted,
} from '../../css/style';
import { JobMatchCardStyle } from '../../css/jobMatchCardStyle';
import { badgeColor, IMG_STORAGE_LINK, CORONADO_BADGE_TITLE } from '../constants/Constants';
import {
  getJobRoleText,
  isReliefOrExternshipType,
  formatPostTitleNoTime, formatShiftTime,
} from '../../utils/Functions';

const fastpassIcon = `${IMG_STORAGE_LINK}green-ticket.png`;

class PracticeJobMatchCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  viewMatchDetails = (item) => {
    const { search } = this.props;
    localStorage.setItem('viewDetailsMatchIdStr', item.id);
    this.props.history.push(`/${search ? 'jobpostings' : 'matches'}/${item.jobPosting ? item.jobPosting.id : item.id}`);
  };

  getBadge(title, id) {
    const { classes } = this.props;
    return (
      <Button
        className={classes.badgeFilter}
        key={title}
        style={{
          backgroundColor: badgeColor(id || title),
        }}
        ref={title}
      >
        {title}
      </Button>
    );
  }

  getBadges() {
    const { item } = this.props;
    return item && item.badges && item.badges.length && (
      <div
        style={{
          height: 30,
          overflow: 'hidden',
          paddingRight: '50px',
          position: 'relative',
          marginTop: 10,
        }}
      >
        {item.badges.map(badge => this.getBadge(badge.title, badge.id))}
        {this.moreBadges()}
      </div>
    ) || '';
  }

  moreBadges() {
    const { item } = this.props;
    let lineTops = [];
    const publicRef = this.refs.public;
    const privateRef = this.refs['private-staff'];
    const customRef = this.refs.superhero;
    let firstLineTop = customRef ? customRef.offsetTop : null;
    if (!firstLineTop) {
      firstLineTop = publicRef ? publicRef.offsetTop : null;
    }
    if (!firstLineTop) {
      firstLineTop = privateRef ? privateRef.offsetTop : null;
    }
    if (firstLineTop && publicRef && firstLineTop !== publicRef.offsetTop) {
      lineTops.push(privateRef.offsetTop);
    }
    if (firstLineTop && privateRef && firstLineTop !== privateRef.offsetTop) {
      lineTops.push(privateRef.offsetTop);
    }
    item.badges.map((badge) => {
      let ref = this.refs[badge.title];
      if (ref) {
        if (!firstLineTop) {
          firstLineTop = ref.offsetTop;
        }
        if (ref.offsetTop != firstLineTop) {
          lineTops.push(ref.offsetTop);
        }
      }
    });
    if (lineTops && lineTops.length > 0) {
      return (
        <span
          style={{
            position: 'absolute',
            top: 10,
            right: 0,
            fontSize: '14px',
            fontFamily: 'Asap',
          }}
        >
          +
          {lineTops.length}
          {' '}
          more
        </span>
      );
    } else {
      return null;
    }
  }


  render() {
    const {
      classes,
      item,
      confirmConnectToMatch,
      user,
      history,
      search,
      enableDelete,
      deleteMatch = () => {
      },
      fastpassData,
    } = this.props;
    const { practice } = item;
    const showNewIcon = item.status === 'new';
    const showConnectButton = !!user && item.status !== 'connected';
    const showSignInButton = !user;
    const showDeleteButton = enableDelete && !!user;
    const enableConnectButton = user && user.isVerified;
    const jobPosting = search ? item : item.jobPosting;
    // const boostDescription = isPrivateStaff(user) ? jobPosting?.staffDescription : jobPosting?.contractDescription;
    // const isBoostedJob = checkIsBoostedJob(boostDescription, jobPosting);
    const isBoostedJob = jobPosting?.isBoostedByDates;
    const isConnected = item.status === 'connected';
    const isArchived = jobPosting && jobPosting.deletedAt && !jobPosting.isDeleted;
    const isDeleted = jobPosting && jobPosting.deletedAt && jobPosting.isDeleted;
    const isgreyedOut = (isConnected && isDeleted) || false;
    const jobRoleText = getJobRoleText(item?.jobPosting?.talentType, item?.jobPostingCriteriaItems);
    const rotationTypes = item.jobPosting && item.jobPosting.rotationTypes && JSON.parse(item.jobPosting.rotationTypes);
    const isFastPass = !isEmpty(item) && jobPosting?.typeOfEmployment === 'Relief'
      && fastpassData?.find(fp => fp.practice_id === item?.practice?.id);

    const shiftTime = formatShiftTime(jobPosting);
    return (
      <div className={classes.cardContainer}>
        <Card
          className={classes.card}
          style={{
            margin: 8,
          }}
        >
          <CardContent style={{ padding: '8px' }}>
            <Grid container>
              <Grid item xs={10} className={classes.cardHeaderContainer}>
                {isBoostedJob && (<span className={classes.boostIcon}>$</span>)}
                <Typography className={classes.jobTitle}>
                  {formatPostTitleNoTime(jobPosting?.title)}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.iconGridItem} key={`${item.id}_delete_item`}>
                {showNewIcon
                  && (
                    <Tooltip title="NEW">
                      <NewReleasesIcon className={classes.newIcon} />
                    </Tooltip>
                  )
                }
                {showDeleteButton
                  && (
                    <Tooltip title="Delete">
                      <img
                        src={`${IMG_STORAGE_LINK}delete_icon.svg`}
                        onClick={() => deleteMatch()}
                        className={classes.deleteIcon}
                        key={`${item.id}_delete_btn`}
                      />
                    </Tooltip>
                  )
                }
              </Grid>
            </Grid>
            <div className={classes.cardHeaderContainer}>
              {shiftTime && (<AccessTimeIcon className={classes.shiftTimeIcon} />)}
              <Typography className={classes.shiftTimeTitle}>
                {shiftTime}
              </Typography>
            </div>
            {this.getBadges()}
          </CardContent>
          <CardActions style={{ padding: '8px' }}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <div className={classes.cardHeaderContainer}>
                  <div style={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: `#${item.savedSearch.color}`,
                    marginRight: 4,
                    marginLeft: 3,
                  }}
                  />
                  <Typography className={classes.searchTitle}>
                    {item.savedSearch.title === 'Externship Work Near Me' ? 'Externships Near Me' : item.savedSearch.title}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} className={classes.cardActions}>
                {showSignInButton
                  && (
                    <Button
                      key={`${item.id}_login_btn`}
                      size="small"
                      className={classes.lightNavyButton}
                      variant="contained"
                      color="primary"
                      onClick={e => history.push('/login')}
                    >
                      Sign in to connect!
                    </Button>
                  )}
                {showConnectButton
                  && (
                    <Button
                      key={`${item.id}_connect_btn`}
                      size="small"
                      disabled={!enableConnectButton}
                      className={classes.redButton}
                      style={{ marginRight: '8px' }}
                      variant="contained"
                      onClick={(e) => {
                        localStorage.setItem('viewDetailsMatchIdStr', item.id);
                        return confirmConnectToMatch(item);
                      }}
                    >
                      Connect
                    </Button>
                  )}
                {showConnectButton
                  && (
                    <Button
                      key={`${item.id}_details_btn`}
                      size="small"
                      className={classes.lightNavyButton}
                      variant="contained"
                      onClick={e => this.viewMatchDetails(item)}
                    >
                      Details
                    </Button>
                  )}
                {isConnected
                  && (
                    <Button
                      key={`${item.id}_msg_btn`}
                      size="small"
                      className={classes.lightNavyButton}
                      variant="contained"
                      onClick={(e) => {
                        localStorage.setItem('viewDetailsMatchIdStr', item.id);
                        history.push(`/messages/${item.id}`);
                      }}
                    >
                      Message/Request Shifts
                    </Button>
                  )}
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    );
  }
}

PracticeJobMatchCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any),
  search: PropTypes.bool,
  confirmConnectToMatch: PropTypes.func,
  refreshStats: PropTypes.func,
  enableDelete: PropTypes.bool,
};

PracticeJobMatchCard.defaultProps = {
  user: null,
  actions: null,
  enableDelete: false,
  confirmConnectToMatch: () => {
  },
};

export default withStyles(JobMatchCardStyle)(withRouter(PracticeJobMatchCard));
