import {
  Card,
  CardContent,
  Tooltip,
  Typography,
  ButtonBase,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import DomainIcon from '@mui/icons-material/Domain';
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import { PropTypes } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { orange, violetBlue } from '../../css/style';
import { JobMatchCardStyle } from '../../css/jobMatchCardStyle';
import { IMG_STORAGE_LINK } from '../constants/Constants';

const fastpassIcon = `${IMG_STORAGE_LINK}green-ticket.png`;

class PracticeMatchCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes,
      isMobile,
      practice,
      fastpassData,
      showSchedule,
      onClick,
      isSelected,
    } = this.props;
    const isFastPass = fastpassData?.find(fp => fp.practice_id === practice?.id);

    return <div className={isMobile ? classes.cardContainerMobile : classes.cardContainer} onClick={onClick}
                style={{ backgroundColor: isSelected ? violetBlue : 'transparent'}}>
      <Card
        className={classes.card}
        style={{
          margin: 8,
          cursor: 'pointer',
        }}
      >
        <CardContent style={{ padding: '8px' }}>
          <Grid container>
            <Grid item xs={10} className={classes.cardHeaderContainer} style={{ height: '55px' }}>

              <Tooltip title={practice?.isPriorityHospital ? 'Priority Hospital' : ''} placement="top">
                <DomainIcon style={practice?.isPriorityHospital ? {
                  color: orange,
                  border: `1px solid ${orange}`,
                  borderRadius: 20,
                  padding: 2,
                  width: 23,
                  height: 23
                } : {}} className={classes.matchItemLinkIcon}/>
              </Tooltip>
              <Typography className={classes.jobTitle}>{practice ? practice.name : '-'}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.matchItemInfo}>
            <Grid item xs={6} className={classes.matchIteminfoItem}>
              <PlaceIcon className={classes.matchIteminfoIcon}/>
              <Typography className={classes.matchItemText}>{practice?.city || ''}, {practice?.state || ''}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.matchIteminfoItem} style={{ justifyContent: 'flex-end' }}>
              <ButtonBase onClick={() => showSchedule(practice)}>
                <EventIcon className={classes.matchItemLinkIcon}/>
                <Typography className={classes.matchItemLink}>Calendar</Typography>
              </ButtonBase>
            </Grid>
          </Grid>
          <Grid container className={classes.matchItemInfo}>
            <Grid item xs={6} className={classes.cardHeaderContainer}
                  style={{ visibility: isFastPass ? 'visible' : 'hidden' }}>
              <img style={{
                width: 20,
                height: 20,
                marginRight: '5px',
              }} src={fastpassIcon} alt="fastpass" title="fastpass"/>
              <Typography className={classes.headerText}>FAST PASS</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>;
  }
}

PracticeMatchCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  practice: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any),
};

PracticeMatchCard.defaultProps = {
  user: null,
  actions: null,
};

export default withStyles(JobMatchCardStyle)(withRouter(PracticeMatchCard));
