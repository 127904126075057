import { ButtonBase, Grid, Typography } from '@mui/material';
import JobMatchCard from '../subcomponents/JobMatchCard';
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import PracticeMatchCard from '../subcomponents/PracticeMatchCard';
import PracticeJobMatchCard from '../subcomponents/PracticeJobMatchCard';
import { navy, selectedPractice, violetBlue } from '../../css/style';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


class PracticeMatchView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      matches,
      user,
      actions,
      unreadMsgCounts,
      fastpassData,
      reliefDates,
      deleteMatch,
      showSchedule,
      handlePracticeClick,
      matchToBeDeleted,
      isMatchDeleted,
      refreshStats,
      confirmConnectToMatch,
      matchPractices,
      isMobile,
    } = this.props;

    if (isMobile) {
      return this.renderMobile(matchPractices, matches, user, actions, fastpassData, showSchedule, refreshStats, reliefDates, confirmConnectToMatch, unreadMsgCounts, deleteMatch, handlePracticeClick, matchToBeDeleted, isMatchDeleted);
    }
    return this.renderDesktop(matchPractices, matches, user, actions, fastpassData, showSchedule, refreshStats, reliefDates, confirmConnectToMatch, unreadMsgCounts, deleteMatch, handlePracticeClick, matchToBeDeleted, isMatchDeleted);
  }

  renderDesktop(matchPractices, matches, user, actions, fastpassData, showSchedule, refreshStats, reliefDates, confirmConnectToMatch, unreadMsgCounts, deleteMatch, handlePracticeClick, matchToBeDeleted, isMatchDeleted) {
    let {
      handlePracticeSelected = (p) => { },
      selectedPractice,
    } = this.props;
    selectedPractice = selectedPractice || (matchPractices.length ? matchPractices[0] : null);
    if (selectedPractice && matchPractices.length && !matchPractices.filter(practice => practice.id === selectedPractice.id).length) {
      selectedPractice = matchPractices[0];
    }
    const filteredMatches = selectedPractice ? matches.filter(item => item.practice?.id === selectedPractice.id) : matches;
    const r0 = matchPractices.length && matchPractices[0].id === selectedPractice?.id;

    return (
      <>
        <Grid item xs={6}>
          <Typography align="center" style={{
            color: navy,
            fontWeight: 800
          }}>Practices</Typography>
          {matchPractices.map(practice => (
            <PracticeMatchCard
              user={user}
              actions={actions}
              practice={practice}
              fastpassData={fastpassData}
              showSchedule={showSchedule}
              isSelected={selectedPractice?.id === practice.id}
              onClick={() => {
                handlePracticeSelected(practice);
              }}
            />
          ))}
        </Grid>
        <Grid item xs={6}>
          <Typography align="center" style={{
            color: navy,
            fontWeight: 800
          }}>Shift Matches</Typography>
          <div style={{
            backgroundColor: violetBlue,
            borderRadius: `${r0 ? '0' : '10px'} 10px 10px 10px`,
          }}
          >
            {filteredMatches.map(item => (
              <PracticeJobMatchCard
                enableDelete
                user={user}
                refreshStats={refreshStats}
                actions={actions}
                item={item}
                reliefDates={reliefDates}
                confirmConnectToMatch={() => confirmConnectToMatch(item)}
                unreadCount={!isEmpty(unreadMsgCounts) ? unreadMsgCounts[item.id] : 0}
                deleteMatch={() => deleteMatch(item)}
                handlePracticeClick={handlePracticeClick}
                open={!(matchToBeDeleted.id === item.id && isMatchDeleted)}
                fastpassData={fastpassData}
              />
            ))}
          </div>
        </Grid>
      </>
    );
  }

  renderPracticeMatches(selectedPractice, matches, user, actions, fastpassData, showSchedule, refreshStats, reliefDates, confirmConnectToMatch, unreadMsgCounts, deleteMatch, handlePracticeClick, matchToBeDeleted, isMatchDeleted) {
    const filteredMatches = matches.filter(item => item.practice?.id === selectedPractice.id);
    return (
      <Grid item xs={12}>
        <Grid container direction="row" alignContent="center">
          <Grid item xs={1}>
            <ButtonBase onClick={() => this.setState({ selectedPractice: null })}>
              <ChevronLeftIcon style={{
                fontSize: 44,
                color: navy,
              }}
              />
            </ButtonBase>
          </Grid>
          <Grid item xs={10}>
            <Typography
              color="secondary"
              style={{
                fontSize: 26,
                fontWeight: 800,
              }}
            >
              {selectedPractice.name}
            </Typography>
          </Grid>
        </Grid>
        {filteredMatches.map(item => (
          <PracticeJobMatchCard
            enableDelete
            user={user}
            refreshStats={refreshStats}
            actions={actions}
            item={item}
            reliefDates={reliefDates}
            confirmConnectToMatch={() => confirmConnectToMatch(item)}
            unreadCount={!isEmpty(unreadMsgCounts) ? unreadMsgCounts[item.id] : 0}
            deleteMatch={() => deleteMatch(item)}
            handlePracticeClick={handlePracticeClick}
            open={!(matchToBeDeleted.id === item.id && isMatchDeleted)}
            fastpassData={fastpassData}
          />
        ))}
      </Grid>
    );
  }

  renderMobile(matchPractices, matches, user, actions, fastpassData, showSchedule, refreshStats, reliefDates, confirmConnectToMatch, unreadMsgCounts, deleteMatch, handlePracticeClick, matchToBeDeleted, isMatchDeleted) {
    const { selectedPractice } = this.props;
    if (selectedPractice) {
      return this.renderPracticeMatches(selectedPractice, matches, user, actions, fastpassData, showSchedule, refreshStats, reliefDates, confirmConnectToMatch, unreadMsgCounts, deleteMatch, handlePracticeClick, matchToBeDeleted, isMatchDeleted);
    }
    return (
      <Grid item xs={12}>
        {matchPractices.map(practice => (
          <PracticeMatchCard
            user={user}
            actions={actions}
            practice={practice}
            fastpassData={fastpassData}
            showSchedule={showSchedule}
            isMobile
            isSelected={selectedPractice?.id === practice.id}
            onClick={() => this.setState({ selectedPractice: practice })}
          />
        ))}
      </Grid>
    );
  }
}

export default PracticeMatchView;
